<template>
  <div class="contentDologin">
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="0"
      class="demo-ruleForm"
    >
      <div class="title">
        <div>忘记密码</div>
        <div class="return" @click="returnLogin">
          <i class="el-icon-arrow-left"></i>返回登录
        </div>
      </div>
      <el-form-item label="" prop="phone">
        <el-input
          class="input"
          v-model="form.phone"
          placeholder="请输入手机号"
        />
      </el-form-item>
      <el-form-item label="" prop="code">
        <!-- <el-input class="input" placeholder="请输入验证码" /> -->
        <div class="input" style="position: relative; border: 0">
          <el-input
            style="margin-top: 0"
            class="input"
            maxlength="4"
            v-model="form.code"
            placeholder="请输入验证码"
          />
          <span class="yzm" @click.stop="sendCode">{{ buttonText }}</span>
        </div>
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input
          class="input"
          v-model="form.password"
          placeholder="请输入新密码"
        />
      </el-form-item>
      <el-form-item label="" prop="passwordTwo">
        <el-input
          class="input"
          v-model="form.passwordTwo"
          placeholder="请确认新密码"
        />
      </el-form-item>
      <div class="checkBox" @click="checkType = !checkType">
        <img v-if="checkType" src="@/assets/images/checkTrue.png" />
        <img v-else src="@/assets/images/checkFalse.png" />
        使用前需同意<span @click.stop="openDio(0)">《用户条款》</span>和<span
          @click.stop="openDio(1)"
          >《隐私声明》</span
        >
      </div>

      <el-button class="btn" @click="uploadPwd">确认修改</el-button>

      <div class="text">安徽盟数科技有限公司</div>
    </el-form>
  </div>
</template>

<script>
import { passwordForget, passwordCodeGet } from "@/api/lx";
export default {
  name: "LoginPwd",
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      checkType: false,
      form: {
        phone: "", //手机号
        code: "", //验证码
        password: "", //密码
        passwordTwo: "", //密码
      },
      buttonText: "获取验证码",
      isCounting: false,
      countDown: 60,
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          // { min: 6, max: 20, message: "密码长度在6~20个数字", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 20, message: "密码长度在6~20个数字", trigger: "blur" },
        ],
        passwordTwo: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    sendCode() {
      // 验证手机号
      this.$refs["ruleForm"].validateField("phone", (errorMessage) => {
        if (!errorMessage) {
          if (this.isCounting) {
            return;
          }
          this.isCounting = true;
          passwordCodeGet({
            phone: this.form.phone,
          })
            .then((res) => {
              // return;
              this.countDown = 60;
              this.buttonText = `${this.countDown}秒后重新获取`;
              this.timer = setInterval(() => {
                this.countDown--;
                this.buttonText = `${this.countDown}秒后重新获取`;
                if (this.countDown <= 0) {
                  clearInterval(this.timer);
                  this.isCounting = false;
                  this.buttonText = "获取验证码";
                }
              }, 1000);
            })
            .catch((err) => {
              this.isCounting = false;
            });
        }
      });
    },
    openDio(val) {
      this.$parent.open(val);
    },
    returnLogin() {
      this.$parent.changeType(1);
    },
    uploadPwd() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (!this.checkType) {
            this.$message.error("请阅读并勾选用户条款和隐私声明");
            return;
          }

          passwordForget({
            ...this.form,
          }).then(async (res) => {
            // return;
            console.log("1231", res);
            if(res.code==200){
              this.$message.success('操作成功请登录');
              this.$parent.changeType(1);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.contentDologin {
  width: 100%;
  height: 100%;
  padding: 190px 101px 0 148px;
  box-sizing: border-box;
}
.title {
  width: 100%;
  box-sizing: border-box;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 40px;
  color: #00161a;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .return {
    width: 106px;
    height: 40px;
    background: #00d8c3;
    border-radius: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .l-icon-arrow-left {
      display: block;
      font-size: 14px !important;
    }
  }
}
.input {
  width: 380px;
  height: 50px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #dcdfe6;
  margin-top: 20px;
  font-size: 16px;
  /deep/ .el-input__inner {
    width: 100%;
    height: 100%;
  }
}
.yzmBox {
  width: 380px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .input {
    width: 231px;
    height: 50px;
    margin-top: 0;
  }
  img {
    width: 139px;
    height: 50px;
    border-radius: 8px;
    display: block;
  }
}
.checkBox {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #525f66;
  margin-top: 37px;
  cursor: pointer;
  span {
    color: #00d8c3;
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}
.btn {
  width: 100%;
  height: 59px;
  background: #00d8c3;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 57px;
  border: 1px solid #00d8c3;
  &.is-active,
  &:active {
    border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
    outline: none; /* 移除默认的轮廓线 */
  }
}
.text {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #bdc2cb;
  line-height: 20px;
  text-align: center;
  margin-top: 100px;
}
.yzm {
  height: 100%;
  width: 115px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #00d8c3;
  position: absolute;
  top: 0px;
  text-align: right;
  line-height: 50px;
  cursor: pointer;
  z-index: 900;
  right: 20px;
}
/deep/.el-form-item {
  margin: 0;
}
</style>