<template>
  <div class="content">
    <div class="contentL">
      <img class="loginTopImg" src="@/assets/images/loginLogo.png" />
      <img class="loginCenter" src="@/assets/images/loginLogo2.png" />
    </div>
    <div class="contentR">
      <LoginDologin v-if="type == 1" />
      <LoginPwd v-else />
    </div>

    <el-dialog
      :title="richType==0?'《盟数合作伙伴线上平台用户条款》':'《盟数合作伙伴线上平台隐私声明》'"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div class="rich" v-html="rich">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { agreementView } from "@/api/lx";
import LoginDologin from "./LoginDologin.vue";
import LoginPwd from "./LoginPwd.vue";
export default {
  name: "login",
  components: {
    LoginDologin,
    LoginPwd,
  },
  data() {
    return {
      type: 1,
      dialogVisible: false,
      rich:'',
      richType:0,//0《用户条款》 1《隐私声明》
    };
  },
  
  created() {
 
  },

  methods: {
    getAgreementView() {
      agreementView().then(async (res) => {
        if(this.richType == 0){
          this.rich = res.data.agreement
        }else{
          this.rich = res.data.privacyPolicy
        }
  
         this.dialogVisible = true
      });
    },
    changeType(val) {
      this.type = val;
    },
    open(val){
      this.richType = val
      this.getAgreementView();

    }
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100vh;
  display: flex;
  .contentL {
    width: 1290px;
    height: 100%;
    background-image: url("../../src/assets/images/logoBj.png");
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .loginTopImg {
      position: absolute;
      width: 196.76px;
      height: 46px;
      top: 86px;
      left: 80px;
    }
    .loginCenter {
      width: 480px;
      height: 100px;
    }
  }
}
.contentR {
  width: 630px;
  height: 100%;
}
.rich {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #00161a;
  width: 100%;
  height: 661px;
  background: #f3f6f9;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>